import React from 'react';
import PropTypes from 'prop-types';

const PreFooter = ({ i18n, country }) => (
  <div className="prefooter">
    {country === 'mx' ? (
      <p className="prefooter--disclaimer">
        {i18n.gettext(
          '*Este beneficio está disponible a partir del nivel Silver.',
        )}
      </p>
    ) : (
      <p className="prefooter--disclaimer">
        {i18n.gettext(
          '¹Este beneficio está disponible a partir del nivel Silver.',
        )}
      </p>
    )}
    {country !== 'mx' && (
      <p className="prefooter--disclaimer">
        {i18n.gettext('²Este beneficio está sujeto a volumen de clientes.')}
      </p>
    )}
  </div>
);

PreFooter.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  country: PropTypes.string.isRequired,
};

export default PreFooter;
