import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@andes/button';
import ChevronUp24 from '@andes/icons/ChevronUp24';
import { Image } from 'nordic/image';

import getTextsByCountry from '../../../texts/getTexts';

const Footer = ({ i18n, background, lang, country, isYellowTheme }) => (
  <section className={`footer ${background}`}>
    <Image
      src="agencies/home/Footter.svg"
      alt=""
      className="background-rebranding background-rebranding--top"
    />
    <div className="footer--content">
      <h1 className="footer--content-title">
        {getTextsByCountry(country, 'Footer_agencies_title', i18n)}
      </h1>
      <Button
        hierarchy=""
        className="icon-container"
        data-testid="scroll-top-button"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ChevronUp24 color={isYellowTheme ? 'black' : 'white'} />
      </Button>
      <p className="footer--content-details">
        {getTextsByCountry(country, 'footer_description', i18n)}
      </p>
      <Button
        className="footer--content-action"
        href={`/partners/agencies/${lang}/subscribe#from=partners_agencies_footer_button&to=subscribe`}
        hierarchy="transparent"
      >
        {i18n.gettext('Quiero formar parte')}
      </Button>
    </div>
  </section>
);

Footer.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  background: PropTypes.string,
  lang: PropTypes.string,
  country: PropTypes.string,
  isYellowTheme: PropTypes.bool,
};

export default Footer;
