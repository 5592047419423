import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

const EventsImage = ({ isMobile, country }) => {
  const version = country === 'ar' ? '_v2' : '_v1';

  return (
    <Image
      className="events--image"
      src={`agencies/events/events_image_${country}${
        isMobile ? '_mobile' : ''
      }${version}.png?v=1`}
      alt=""
      // onContextMenu={() => false}
    />
  );
};

EventsImage.propTypes = {
  isMobile: PropTypes.bool,
  country: PropTypes.string,
};

export default EventsImage;
